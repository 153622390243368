import Flex from 'components/Flex';
import { headerHeight, mobileMenuWidth } from 'pages/App/Header/constants';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { TDisplaySize } from 'theme';

interface IMenu {
  boundary: TDisplaySize;
}

interface IMobileMenu extends IMenu {
  close: boolean;
}

export const DesktopMenuBody = styled(Flex)<IMenu>`
  ${(props) => props.theme.media[props.boundary]`
    display: none;
  `}
`;

export const MobileMenuOverlay = styled.div<IMobileMenu>`
  ${(props) => props.theme.display[props.boundary]};
  position: fixed;
  top: ${headerHeight};
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  opacity: 1;
  transition: opacity 0.3s ease 0s;
  z-index: 99;
  ${(props) =>
    props.close &&
    `
    opacity: 0;
    transform: translate3d(-100%, 0px, 0px);
  `}
`;

export const MobileMenuBody = styled.div<IMobileMenu>`
  ${(props) => props.theme.display[props.boundary]};
  position: fixed;
  top: ${headerHeight};
  overflow-y: auto;
  left: 0;
  height: calc(100% - ${headerHeight});
  width: ${mobileMenuWidth};
  background-color: ${(props) => props.theme.colors.dark};
  z-index: 100;
  transition: all 0.5s ease 0s;
  ${(props) =>
    props.close &&
    `
    transform: translate3d(-100%, 0px, 0px);
  `}
`;

export const NavMenuItem = styled(NavLink)<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  padding: 10px;
  color: ${(props) => props.theme.colors.menuText};
  cursor: pointer;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  border-radius: 4px;
  transition: 0.3s all;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};
  ${({ theme }) => theme.fillSvg(theme.colors.inputText)};
  &.active {
    background-color: ${(props) => props.theme.colors.accentNormal};
    color: ${(props) => props.theme.colors.snow};
    ${({ theme }) => theme.fillSvg(theme.colors.snow)};
  }
  &:hover {
    background-color: ${(props) => props.theme.colors.accentNormal};
    color: ${(props) => props.theme.colors.snow};
    ${({ theme }) => theme.fillSvg(theme.colors.snow)};
  }
`;
