import Button from 'components/Button';
import Card from 'components/Card';
import ClearInput from 'components/Form/ClearInput';
import { IOption } from 'components/Form/Select/SingleSelect';
import useFocus from 'hooks/useFocus';
import { ReactComponent as SearchIcon } from 'icons/search.svg';
import React, { ChangeEvent, FunctionComponent, useCallback, useState, useEffect } from 'react';
import fadeIn from 'react-animations/lib/fade-in';
import styled, { keyframes } from 'styled-components/macro';
import { ITheme } from 'theme';

interface IAutocomplete {
  className?: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  inputProps?: object;
  onSearch?: (value?: string) => void;
  initialValue?: string;
  theme?: ITheme;
  suggestions?: IOption[];
  onChange?: (value?: string) => void;
  onSelect?: (value?: IOption) => void;
  error?: boolean;
  isNeedButton?: boolean;
}

const Autocomplete: FunctionComponent<IAutocomplete> = ({
  className,
  inputProps,
  initialValue = '',
  onSearch,
  suggestions = [],
  onChange,
  onSelect,
  isNeedButton = true,
  error = false,
}) => {
  const [value, setValue] = useState(initialValue);
  const [isFocused, bind] = useFocus();

  useEffect(() => setValue(initialValue), [initialValue]);

  const onChangeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      setValue(event.target.value);
      onChange && onChange(event.target.value);
    },
    [onChange]
  );
  const onSearchHandler = useCallback(() => onSearch && onSearch(value), [value, onSearch]);
  const setSelectedValue = useCallback(
    (item: IOption) => (): void => {
      setValue(item.label);
      bind.setIgnoreBlur(false);
      bind.onBlur();
      onSelect && onSelect(item);
    },
    []
  );
  const ignoreBlurHandler = useCallback((param) => () => bind.setIgnoreBlur(param), []);

  return (
    <Wrapper className={className}>
      <StyledSearchIcon />
      <ClearInputStyled
        value={value}
        onChange={onChangeHandler}
        error={error}
        onFocus={bind.onFocus}
        onBlur={bind.onBlur}
        isNeedButton={isNeedButton}
        {...inputProps}
        border
      />
      {isNeedButton && <StyledButton onClick={onSearchHandler}>Search</StyledButton>}
      <Body
        hidden={!isFocused}
        onMouseEnter={ignoreBlurHandler(true)}
        onMouseLeave={ignoreBlurHandler(false)}
      >
        {!value && <NoSuggestions>Start typing for suggestions</NoSuggestions>}
        {!suggestions.length && value && <NoSuggestions>No suggestions</NoSuggestions>}
        {value &&
          suggestions.map((item) => (
            <BodyItem key={item.label} onClick={setSelectedValue(item)}>
              {item.label}
            </BodyItem>
          ))}
      </Body>
    </Wrapper>
  );
};

export default Autocomplete;

const Wrapper = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
`;

export const ClearInputStyled = styled(ClearInput)<{ isNeedButton: boolean }>`
  padding: ${(props) => (props.isNeedButton ? '16px 169px 16px 61px' : '16px 22px 16px 61px')};
  ${(props) => props.theme.media.xs`
    padding-right: ${props.isNeedButton ? '100px' : '22px'}};
  `};
  &:disabled {
    background: ${(props) => props.theme.colors.lightGrey};
  }
`;

export const StyledSearchIcon = styled(SearchIcon)`
  position: absolute;
  left: 20px;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
`;

export const StyledButton = styled(Button)`
  padding: 13px 36.5px;
  position: absolute;
  right: -2px;
  top: 0;
  bottom: 0;
  ${(props) => props.theme.media.xs`
    padding: 13px 20px;
  `};
  outline: none;
`;

const fadeInAnimation = keyframes`${fadeIn}`;

export const Body = styled(Card)`
  margin-top: 8px;
  position: absolute;
  width: 100%;
  border-radius: 4px;
  padding: 8px 16px;
  animation: 0.3s ${fadeInAnimation};
  text-align: left;
  max-height: 190px;
  overflow-y: auto;
  z-index: 2;
`;

export const BodyItem = styled.p`
  font-weight: 500;
  line-height: 24px;
  font-size: 16px;
  padding: 8px 0;
  color: ${(props) => props.theme.colors.darkGrey};
  cursor: pointer;
`;

export const NoSuggestions = styled.p`
  text-align: center;
  color: ${(props) => props.theme.colors.inputText};
`;
