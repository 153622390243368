import 'intl-tel-input/build/css/intlTelInput.css';
import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import intlTelInput, { SomeOptions, Iti } from 'intl-tel-input';
import styled from 'styled-components';
import { IInputComponent, StyledInput } from 'components/Form/Input';

export interface IProps extends IInputComponent {
  telOptions?: SomeOptions;
}

const PhoneInput: FunctionComponent<IProps> = ({
  telOptions = {
    countryOrder: ['ca', 'us'],
    initialCountry: 'ca',
    separateDialCode: true,
    utilsScript: 'https://cdn.jsdelivr.net/npm/intl-tel-input@23.9.3/build/js/utils.js',
    autoPlaceholder: 'off',
  },
  onChange,
  onBlur,
  value,
  ...rest
}) => {
  const inputEl = useRef<HTMLInputElement>(null);
  const [iti, setIti] = useState<Iti | null>(null);
  const [isChanged, setIsChanged] = useState<boolean>(false);
  const changeHandler = useCallback(() => {
    setIsChanged(true);
    onChange(iti?.getNumber());
  }, [iti, onChange]);

  const keyPressHandler = useCallback((event) => {
    if (!/[0-9\-]/.test(event.key)) {
      event.preventDefault();
    }
  }, []);

  useEffect(() => {
    if (inputEl?.current && !isChanged) {
      if (!iti) {
        const intlTel = intlTelInput(inputEl.current, telOptions);
        setIti(intlTel);
        intlTel.setNumber(value || '');
        inputEl.current.addEventListener('countrychange', () => onChange(intlTel.getNumber()));
      } else {
        if (value) {
          iti.setNumber(value);
        }
      }
    }
  }, [inputEl, value, isChanged, onChange]);

  return (
    <PhoneWrapper>
      <StyledInput ref={inputEl} onKeyUp={changeHandler} onKeyPress={keyPressHandler} {...rest} />
    </PhoneWrapper>
  );
};

export default PhoneInput;

const PhoneWrapper = styled.div`
  .iti {
    display: block;
  }
  .iti__selected-country-primary {
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
  }
`;
