import useCommon from 'hooks/useCommon';
import { TAllPermissions, TPermissionType } from 'hooks/usePermission';
import accountMainRoutes from 'pages/App/Layouts/AuthorizedLayout/routes/account';
import companyMainRoutes from 'pages/App/Layouts/AuthorizedLayout/routes/company';
import accountAuthRoutes from 'pages/Auth/AuthorizedSignUp/routes/account';
import companyAuthRoutes from 'pages/Auth/AuthorizedSignUp/routes/company';
import { LazyExoticComponent, ReactElement, useMemo } from 'react';

export interface ILink {
  icon: ReactElement;
  label: string;
  path: string;
  excludePath?: string;
  dataTest?: string;
}

export interface IRenderRoute {
  path: string;
  component: LazyExoticComponent<any>;
  type: TPermissionType;
  title?: string;
  isTitlePrefix?: boolean;
  videoTutorialsUrl?: string;
  docTutorialsUrl?: string;
  exact?: boolean;
}

export interface IRoutes {
  permission?: TAllPermissions;
  link?: ILink;
  routes?: IRenderRoute[];
  role?: boolean;
}

export interface IUseRoutes {
  mainRoutes: IRoutes[];
  authRenderRoutes: IRenderRoute[];
}

function useRoutes(): IUseRoutes {
  const { isCommonTypeAccount, isCommonTypeUndefined } = useCommon();

  const mainRoutes = useMemo(() => {
    if (isCommonTypeUndefined) {
      return [...companyMainRoutes, ...accountMainRoutes];
    }
    return isCommonTypeAccount ? accountMainRoutes : companyMainRoutes;
  }, [isCommonTypeAccount, isCommonTypeUndefined]);

  const authRenderRoutes = useMemo(() => {
    if (isCommonTypeUndefined) {
      return [...companyAuthRoutes, ...accountAuthRoutes];
    }
    return isCommonTypeAccount ? accountAuthRoutes : companyAuthRoutes;
  }, [isCommonTypeAccount]);

  return { authRenderRoutes, mainRoutes };
}

export default useRoutes;
